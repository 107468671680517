import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import React, { useRef, useState, useEffect } from "react";
import * as data from "./locales/default.json";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import WarningIcon from "@material-ui/icons/Warning";
import apiConfig from "../utilities/Constants";
import axios from "axios";
import { toast } from "react-toastify";
import LinearProgress from "@material-ui/core/LinearProgress";
import { getCurrentLanguage } from "../features/config/configSlice";
import { useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { ALLOWED_FILE_EXTENSIONS } from "../utilities/Constants";

const useStyles = makeStyles((theme) => ({
  pdfTitle: {
    margin: "8px",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  pdfContainer: {
    textAlign: "center",
    position: "relative",
  },
  deleteButton: {
    position: "absolute",
    bottom: 13,
    left: 0,
    right: 2,
    margin: "0 8px",
    borderRadius: 0,
    color: "#fff",
  },
  dialog: {
    "& .MuiPaper-root": {
      background: "#cdd5e3",
    },
  },
  warningText: {
    color: "#ff9800",
    marginTop: "10px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  },
  warningIcon: {
    marginRight: "8px",
  },
  successText: {
    color: "#4caf50",
    marginTop: "10px",
  },
  failedUpload: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(2),
  },
}));

const UploadInvoice = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const inputRef = useRef();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [duplicateFiles, setDuplicateFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const language = useSelector(getCurrentLanguage);
  const [fileNames, setFileNames] = useState({});

  const checkForDuplicates = async (files) => {
    const duplicates = [];
    for (const file of files) {
      const fileBuffer = await file.arrayBuffer();
      const hashBuffer = await crypto.subtle.digest('SHA-256', fileBuffer);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
      
      const formData = new FormData();
      formData.append('fileHash', hashHex);

      try {
        const response = await axios.post(`${apiConfig.BASE_API}/pdfs/check-duplicate`, formData);
        if (response.data.isDuplicate) {
          duplicates.push(file.name);
        }
      } catch (error) {
        console.error("Error checking for duplicate:", error);
      }
    }
    return duplicates;
  };

  const handleFileChange = async (event) => {
    const filesToAdd = Array.from(event.target.files).filter((file) => {
      const extension = file.name.split(".").pop();
      return ALLOWED_FILE_EXTENSIONS.includes(extension.toLowerCase());
    });

    const newDuplicates = await checkForDuplicates(filesToAdd);
    setDuplicateFiles(newDuplicates);

    const allFiles = [...selectedFiles, ...filesToAdd.filter(file => !newDuplicates.includes(file.name))];
    setSelectedFiles(allFiles);

    // Initialize fileNames state with original filenames
    const newFileNames = {...fileNames};
    allFiles.forEach(file => {
      if (!newFileNames[file.name]) {
        newFileNames[file.name] = file.name;
      }
    });
    setFileNames(newFileNames);

    if (allFiles.length > 0 || newDuplicates.length > 0) {
      setOpen(true);
    }
  };

  const handleNameChange = (originalName, newName) => {
    setFileNames(prev => ({...prev, [originalName]: newName}));
  };

  const handleListItemClick = () => {
    inputRef?.current?.click();
  };

  const handleUpload = async () => {
    if (selectedFiles.length === 0 || loading) return;
    setLoading(true);
    setRejectedFiles([]);
    setUploadedFiles([]);
    setDuplicateFiles([]);

    console.log("Selected files:", selectedFiles);

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      console.log("Appending file to formData:", file.name);
      formData.append("files", file);
    });
    formData.append("fileNames", JSON.stringify(fileNames));

    console.log("FormData contents:");
    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    try {
      console.log("Sending request to server (now using uploadFiles)");
      const response = await axios.post(`${apiConfig.BASE_API}/pdfs/uploads`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Server response:", response.data);

      setLoading(false);
      if (response.status === 200) {
        const { uploadedFiles, duplicateFiles, rejectedFiles } = response.data;
        console.log("Uploaded files:", uploadedFiles);
        console.log("Duplicate files:", duplicateFiles);
        console.log("Rejected files:", rejectedFiles);

        setUploadedFiles(uploadedFiles || []);
        setDuplicateFiles(duplicateFiles || []);
        setRejectedFiles(rejectedFiles || []);

        if (uploadedFiles && uploadedFiles.length > 0) {
          toast.success(data.default[language].fileSuccessfulyUploaded);
        }
        if (duplicateFiles && duplicateFiles.length > 0) {
          toast.warning(data.default[language].duplicateFilesWarning);
        }
        if (rejectedFiles && rejectedFiles.length > 0) {
          toast.error(data.default[language].someFilesFailedToUpload);
        }
        setOpen(true);
        console.log("Dialog open state set to true");
      } else {
        toast.error(data.default[language].uploadFailed);
      }
    } catch (error) {
      console.error("Upload error:", error);
      setLoading(false);
      toast.error(data.default[language].uploadFailed);
    }
  };

  const handleCloseModal = () => {
    inputRef.current.value = null;
    setOpen(false);
    setDuplicateFiles([]);
    setUploadedFiles([]);
    setRejectedFiles([]);
    setTimeout(() => {
      setSelectedFiles([]);
    }, 300);
  };

  const getGridSize = () => {
    const gridSizeMap = {
      1: 12,
      2: 6,
      3: 4,
    };

    const selectedFilesLength = selectedFiles?.length || 0;
    return gridSizeMap[selectedFilesLength] || 4;
  };

  const deleteFileByIndex = (indexToDelete) => {
    const updateFiles = [
      ...selectedFiles.slice(0, indexToDelete),
      ...selectedFiles.slice(indexToDelete + 1),
    ];
    setSelectedFiles(updateFiles);
    if (updateFiles.length === 0 && duplicateFiles.length === 0) {
      setOpen(false);
    }
  };

  useEffect(() => {
    console.log("Rejected files state updated:", rejectedFiles);
  }, [rejectedFiles]);

  return (
    <>
      <ListItem
        className={props.classes.listItem}
        onClick={() => handleListItemClick()}
      >
        <ListItemAvatar>
          <DriveFolderUploadIcon style={{ color: "#64ffda", fontSize: 36 }} />
        </ListItemAvatar>
        <ListItemText
          classes={{ primary: props.classes.ListItemText }}
          primary={data.default[language].uploadInvoice}
        />
        <input
          ref={inputRef}
          id="fileInput"
          type="file"
          accept=".pdf,.jpg,.jpeg,.png"
          multiple={true}
          onChange={handleFileChange}
          onClick={null}
          style={{ display: "none" }}
        />
      </ListItem>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialog}
      >
        {console.log("Dialog open state:", open)}
        {loading && <LinearProgress />}
        <DialogTitle id="alert-dialog-title">
          {data.default[language].uploadFileTitle}
        </DialogTitle>
        <DialogContent>
          {console.log("Rendering dialog, uploadedFiles:", uploadedFiles)}
          {console.log("Rendering dialog, duplicateFiles:", duplicateFiles)}
          {console.log("Rendering dialog, rejectedFiles:", rejectedFiles)}
          {duplicateFiles.length > 0 && (
            <div className={classes.warningText}>
              <WarningIcon className={classes.warningIcon} />
              {data.default[language].duplicateFilesWarning}:
              <ul>
                {duplicateFiles.map((file, index) => (
                  <li key={index}>{file}</li>
                ))}
              </ul>
            </div>
          )}
          {selectedFiles.length > 0 && (
            <Grid container spacing={2}>
              {selectedFiles.map((file, index) => (
                <Grid
                  className={classes.pdfContainer}
                  item
                  wrap="wrap"
                  xs={getGridSize()}
                  key={index}
                >
                  <TextField
                    fullWidth
                    label="File Name"
                    value={fileNames[file.name]}
                    onChange={(e) => handleNameChange(file.name, e.target.value)}
                    className={classes.pdfTitle}
                  />
                  {file.name?.toLowerCase()?.endsWith(".pdf") ? (
                    <iframe
                      title={`PDF Viewer ${index}`}
                      src={
                        URL.createObjectURL(file) +
                        "#navpanes=0&statusbar=0&toolbar=0&view=FitH"
                      }
                      width="100%"
                      height="250px"
                    />
                  ) : (
                    <div className="imagePreviewContainer small">
                      <img
                        className="imagePreview"
                        src={URL.createObjectURL(file)}
                        alt={`Preview of ${file.name}`}
                      />
                    </div>
                  )}
                  {selectedFiles.length > 1 && (
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.deleteButton}
                      onClick={() => deleteFileByIndex(index)}
                      startIcon={<DeleteIcon />}
                    >
                      Delete
                    </Button>
                  )}
                </Grid>
              ))}
            </Grid>
          )}
          {uploadedFiles.length > 0 && (
            <div className={classes.successText}>
              {data.default[language].fileSuccessfulyUploaded}:
              <ul>
                {uploadedFiles.map((file, index) => (
                  <li key={index}>{file}</li>
                ))}
              </ul>
            </div>
          )}
          {rejectedFiles.length > 0 && (
            <div className={classes.failedUpload}>
              <Typography variant="subtitle1">
                {data.default[language].rejectedFiles}:
              </Typography>
              <ul>
                {rejectedFiles.map((file, index) => (
                  <li key={index}>{file}</li>
                ))}
              </ul>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container justify="space-between">
            <Grid item>
              <Button onClick={() => handleListItemClick()} color="primary">
                {data.default[language].addMore}
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={() => handleCloseModal()} color="primary">
                {data.default[language].cancel}
              </Button>
              <Button onClick={() => handleUpload()} color="primary" autoFocus disabled={selectedFiles.length === 0}>
                {data.default[language].send}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadInvoice;
