import {
  Button,
  CircularProgress,
  CssBaseline,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { useFormik } from "formik";

import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import { toast } from "react-toastify";
import * as yup from "yup";
import apiConfig from "../../utilities/Constants";
import * as data from "../locales/default.json";
import {
  useAddNewBookingMutation,
  useUpdateBookingMutation,
} from "../../services/BookingApi";
import { useHistory } from "react-router-dom";
import {
  extractNumber,
  getSymbol,
  toExponentialNumber,
} from "../../utilities/Utils";
import TextFieldLoading from "../Loading/TextFieldLoading";
import { getCurrentLanguage } from "../../features/config/configSlice";
import { useSelector } from "react-redux";
import ErrorDialog from "./ErrorDialog";
import { ALLOWED_FILE_EXTENSIONS } from "../../utilities/Constants";

const bookingEntriesSchema = (lang) =>
  yup.object().shape({
    pdfName: yup
      .string()
      .label(lang.fields.image)
      .required(lang.fields.isRequired),
    companyId: yup
      .number()
      .label(lang.fields.company)
      .required(lang.fields.isRequired),
    invoiceIBAN: yup.string().label(lang.fields.invoiceIBAN).nullable(),
    vendorName: yup
      .string()
      .label(lang.fields.vendorName)
      .required(lang.fields.isRequired),
    paidById: yup
      .number()
      .label(lang.fields.paidBy)
      .required(lang.fields.isRequired),
    customerName: yup.string().nullable(),
    invoiceNumber: yup
      .string()
      .label(lang.fields.invoiceNumber)
      .required(lang.fields.isRequired),
    invoiceDate: yup
      .date()
      .label(lang.fields.invoiceDate)
      .max(new Date())
      .required(lang.fields.isRequired),
    invoiceDueDate: yup.date().label(lang.fields.invoiceDueDate).nullable(),
    description: yup.string().nullable(),
    bookingLines: yup.array().of(
      yup.object().shape({
        accountNumber: yup
          .number()
          .label(lang.fields.accountNumber)
          .required(lang.fields.isRequired),
        taxRate: yup
          .string()
          .label(lang.fields.taxRate)
          .required(lang.fields.isRequired),

        currency: yup
          .string()
          .label(lang.fields.currency)
          .required(lang.fields.isRequired),
        amount: yup
          .string()
          .label(lang.fields.amount)
          .required(lang.fields.isRequired),
        taxAmount: yup
          .string()
          .label(lang.fields.taxAmount)
          .required(lang.fields.isRequired)
          .test(
            "tax-amount-check",
            lang.fields.taxAmountError,
            function (value) {
              const amount = extractNumber(this.parent.amount);
              const parsedValue = extractNumber(value);
              if (amount < 0) {
                if (parsedValue < amount) {
                  return false;
                }
              } else if (parsedValue > amount) {
                return false;
              }
              return true;
            }
          ),
        amountInEUR: yup
          .string()
          .label(lang.fields.amountInEUR)
          .required(lang.fields.isRequired),
      })
    ),
  });
const useStyles = makeStyles({
  root: {
    height: "100%",
    flexGrowL: 1,
    "& label": {
      top: "5px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "16px",
    },
    "& label.Mui-focused": {
      color: "#64ffda",
      top: "0",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#64ffda",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#a8b2d1",
      },
      "&:hover fieldset": {
        borderColor: "#a6a0b1",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#64ffda",
      },
    },
  },
  button: {
    background: "#0c987e",
    borderRadius: 3,
    border: "1px solid #64ffda",
    color: "white",
    height: 48,
    marginTop: 16,
    padding: "0 30px",
    "&:hover": {
      backgroundColor: "#1bb598",
    },
    "&.Mui-disabled": {
      color: "white",
    },
  },
  addBookingLinesButton: {
    background: "#cd7e43",
    borderRadius: 3,
    border: "1px solid #fdb374",
    color: "white",
    height: 38,
    padding: "0 18px",
    "&:hover": {
      backgroundColor: "#a55f2b",
    },
    "&.Mui-disabled": {
      color: "white",
    },
  },
  cssLabel: {
    color: "#a8b2d1",
    "&.Mui-focused": {
      color: "#23A5EB",
    },
  },
  itemDivider: {
    height: "1px",
    width: "79%",
    margin: "6px auto 10px auto",
    border: "none",
    backgroundColor: "#1bb598",
  },
  centerItems: {
    margin: "0 auto",
  },
  bookingItemsContainer: {
    position: "relative",
    border: "1px solid #64ffda",
    padding: "22px 0px 22px 28px",
    borderRadius: "5px",
    marginTop: "26px",
  },
  bookingItemsheaderContainer: {
    position: "absolute",
    width: "100%",
    left: "0",
    top: "-20px",
  },
  bookingItemsheaderHolder: {
    margin: "0 auto",
    background: " #112240",
    padding: "0 16px",
    textAlign: "center",
    display: "inline-flex",
    position: "relative",
  },
  bookingTotalItem: {
    position: "absolute",
    background: " #112240",
    padding: "0 4px",
    top: "8px",
    right: "8px",
    maxWidth: "180px",
    overflow: "hidden",
  },
  bookingSubTotalHolder: {
    position: "relative",
  },
  subTotal: {
    top: "-11px",
    position: "absolute",
    width: "85%",
    left: "75px",
  },
  subTotalItem: {
    background: " #112240",
    padding: "0 8px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  formControl: {
    minWidth: "100%",
  },
  formError: {
    textAlign: "start",
    color: "#CF162B",
    margin: "0 8px",
    fontSize: "0.87rem",
  },
  fieldText: {
    fontSize: "14px",
    color: "#a8b2d1",
    paddingTop: "5px",
  },
  icon: {
    color: "#64ffda",
  },
  dateIcon: {
    color: "red",
    background: "green",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  inputColor: {
    color: "#a8b2d1",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  previewContainer: {
    padding: "25px",
    margin: "8px",
    textAlign: "center",
    background: "#ddd",
    color: "#333",
    height: "calc(100vh - 40px)",
    position: "sticky",
    top: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  sectionHeader: {
    fontSize: "26px",
  },
  sectionSubHeader: {
    fontSize: "18px",
  },
  sectionSmallSubHeader: {
    fontSize: "16px",
  },
  deletePopverContainer: {
    textAlign: "center",
    background: "#cdd5e3",
    // borderColor:"#112240",
    color: "#a8b2d1",
    padding: "16px",
  },
  deletePopverHeader: {
    // color: "#a8b2d1",
    color: "#112240",
    fontSize: "16px",
  },
  deletePopoverDivider: {
    height: "1px",
    width: "80%",
    margin: "12px auto",
    border: "none",
    backgroundColor: "#a9a9a9",
  },
  select: {
    color: "#a8b2d1",
    "&:after": {
      borderColor: "#a8b2d1",
    },
    "&:before": {
      borderColor: "#a8b2d1",
    },
  },
  selectText: {
    width: "100%",
    "& .MuiOutlinedInput-input": {
      color: "#a8b2d1",
    },
    "& .MuiInputLabel-root": {
      color: "#a8b2d1",
    },
    "& .MuiFormLabel-root": {
      background: "#112240",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#a8b2d1",
    },
    "&.error": {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#fd4f57",
        "&:hover": {
          borderColor: "#fd4f57",
        },
      },
    },
    "&.check": {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#9aff82",
        "&:hover": {
          borderColor: "#9aff82",
        },
      },
    },
    "&.warn": {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#fdc74f",
        "&:hover": {
          borderColor: "#fdc74f",
        },
      },
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#a8b2d1",
    },
    "&:hover .MuiInputLabel-root": {
      color: "#a8b2d1",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#a8b2d1",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#64ffda",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#64ffda",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#64ffda",
    },
  },
  xmlContainer: {
    padding: "4px 24px",
    margin: "8px",
    textAlign: "center",
    background: "#112240",
    color: "#a8b2d1",
    height: "calc(100% - 40px)",
  },
});
const Form = (props) => {
  const { isEditMode, initialValues, selectedItem } = props;
  const [addNewBooking, { isLoading }] = useAddNewBookingMutation();
  const [updateBooking, { isLoading: isLoadingUpdateBooking }] =
    useUpdateBookingMutation();
  const [extractLoading, setExtractLoading] = useState(false);
  const history = useHistory();
  // const [getBookingById,{isLoading:isLoadingBookingById}] = useGetBookingByIdQuery()
  const classes = useStyles();
  const [showDeletePopover, setShowDeletePopover] = useState(false);
  const [fileObj, setFileObj] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [bookLinesToDelete, setBookLinesToDelete] = useState();
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [companiesList, setCompaniesList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [accountNumberList, setAccountNumberList] = useState([]);
  const [taxRateList, setTaxRateList] = useState([]);
  const [vendorNamesList, setVendorNamesList] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [filesList, setFilesList] = useState([]);
  const [confidence, setConfidence] = useState({
    bookingLines: [
      {
        taxRate: 1,
        currency: 1,
        amount: 1,
      },
    ],
  });
  const language = useSelector(getCurrentLanguage);
  const [rates, setRates] = useState(null);

  const getFiles = async (date) => {
    try {
      const res = await axios.get(
        `${apiConfig.BASE_API}/pdfs${date ? "?date=" + date : ""}`
      );
      let data = res.data;
      // setFilesList(data.files);
      // console.log("xxxxxxxxxxxxxxxxxxxxxxxx", data)

      const allFiles = data
        .filter(item => {
          if (item.status !== "pending") return false;

          const extension = item.name.split(".").pop().toLowerCase();
          return ALLOWED_FILE_EXTENSIONS.includes(extension);
        })
        .map(item => item.name);

      // console.log("xxxxxxxallFilesxxxxxxxxxxxxxxxxx", allFiles)

      setFilesList(allFiles);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (!isEditMode) {
      getFiles();
    }
  }, [isEditMode]);
  const formik = useFormik({
    initialValues: initialValues,

    validationSchema: bookingEntriesSchema(data.default[language]),
    onSubmit: async (values) => {
      try {
        if (isEditMode) {
          updateBooking({
            id: selectedItem,
            ...values,
            confidence: { ...confidence },
          })
            .unwrap()
            .then((res) => {
              if (res.status) {
                history.goBack();
                toast.success(data.default[language].bookingUpdateSuccess);
              } else {
                toast.error(data.default[language].bookingUpdateError);
              }
            })
            .catch((err) => {
              if (err?.status === 403) {
                setErrorMessage(data.default[language].bookingForbidden);
              } else {
                toast.error(data.default[language].bookingUpdateError);
              }
            });
        } else {
          setExtractLoading(false);
          addNewBooking({ ...values, confidence: { ...confidence } })
            .unwrap()
            .then((res) => {
              if (res.status === true) {
                setFilesList([
                  ...filesList.filter((name) => name !== res.fileName),
                ]);
                toast.success(data.default[language].bookingCreated);
                formik.resetForm();
                formik.setValues({ ...formik.initialValues });
                setFileObj(null);
                setRates(null);
              } else {
                toast.error(data.default[language].bookingCreatedError);
              }
            })
            .catch((err) =>
              toast.error(data.default[language].bookingCreatedError)
            );
        }
      } catch (err) {
        toast.error(data.default[language].bookingCreatedError);
      }
    },
  });
  const addNewVendorName = (vendorName) => {
    if (!vendorName) return;
    const isDuplicate = vendorNamesList?.some(
      (item) => item.value === vendorName
    );
    if (!isDuplicate) {
      vendorNamesList.push({
        value: vendorName,
        name: vendorName,
        label: vendorName,
      });
      setVendorNamesList([...vendorNamesList]);
    }
  };
  useEffect(() => {
    formik.setValues({ ...initialValues });
    if (isEditMode) {
      setFilesList([initialValues?.pdfName || ""]);
      addNewVendorName(initialValues?.vendorName);
    }
    setConfidence({
      ...confidence,
      invoiceDate: initialValues.invoiceDateConfidence,
      invoiceDueDate: initialValues.invoiceDueDateConfidence,
      invoiceNumber: initialValues.invoiceNumberConfidence,
      vendorName: initialValues.vendorNameConfidence,
      invoiceIBAN: initialValues.invoiceIBANConfidence,
      customerName: initialValues.customerNameConfidence,
      bookingLines: initialValues?.bookingLines?.map((line) => {
        return {
          taxRate: line.taxRateConfidence,
          amount: line.amountConfidence,
          currency: line.currencyConfidence,
          taxAmount: line.taxAmountConfidence,
        };
      }) ?? [
          {
            taxRate: 0,
            currency: 0,
            amount: 0,
          },
        ],
    });
  }, [initialValues]);
  const getFileInformation = async (fileName) => {
    if (!fileName) return;
    try {
      const url = `${apiConfig.BASE_API}/pdfs/info`;
      const params = {
        name: fileName,
      };
      const response = await axios.get(url, { params });
      if (response.status == 200) {
        setExtractLoading(false);
        const data = response.data;
        const bookingLines =
          data?.bookingLines?.length > 0
            ? data?.bookingLines?.map((line) => {
              return {
                accountNumber: line.accountNumber,
                taxRate: line.taxRate.value,
                amount: line.amount.value,
                amountInEUR: line.amountInEUR,
                currency: line.currency.value,
                taxAmount: line.taxAmount.value,
                taxAmountInEUR: line.taxAmountInEUR,
              };
            })
            : [
              {
                accountNumber: null,
                taxRate: "Hoog",
                amount: "",
                amountInEUR: 0,
                currency: "EUR",
                taxAmount: 0,
                taxAmountInEUR: 0,
              },
            ];
        addNewVendorName(
          data.vendorName?.value ?? formik.initialValues?.vendorName
        );
        formik.setValues({
          ...formik.values,
          invoiceNumber:
            data.invoiceNumber.value ?? formik.initialValues.invoiceNumber,
          invoiceDate:
            data.invoiceDate.value ?? formik.initialValues.invoiceDate,
          invoiceDueDate:
            data.invoiceDueDate.value ?? formik.initialValues.invoiceDueDate,
          description: data.description ?? formik.initialValues.description,
          vendorName: data.vendorName?.value ?? formik.initialValues.vendorName,
          customerName:
            data.customerName.value ?? formik.initialValues.customerName,
          invoiceIBAN:
            data.invoiceIBAN.value ?? formik.initialValues.invoiceIBAN,
          bookingLines: bookingLines ?? formik.initialValues.bookingLines,
        });
        const bookingLinesConfidence =
          data?.bookingLines?.length > 0
            ? data?.bookingLines?.map((line) => {
              return {
                accountNumber: line.accountNumber,
                taxRate: line.taxRate.confidence,
                amount: line.amount.confidence,
                currency: line.currency.confidence,
                taxAmount: line.taxAmount.confidence,
              };
            })
            : [
              {
                taxRate: 1,
                currency: 1,
                amount: 1,
              },
            ];
        setConfidence({
          ...confidence,
          invoiceDate: data.invoiceDate.confidence,
          invoiceDueDate: data.invoiceDueDate.confidence,
          invoiceNumber: data.invoiceNumber.confidence,
          vendorName: data.vendorName.confidence,
          invoiceIBAN: data.invoiceIBAN.confidence,
          customerName: data.customerName.confidence,
          bookingLines: bookingLinesConfidence,
        });
      } else {
        setExtractLoading(true);
        formik.setValues({
          ...formik.initialValues,
          pdfName: fileName,
        });
      }
    } catch (e) {
      setExtractLoading(true);
      formik.setValues({
        ...formik.initialValues,
        pdfName: fileName,
      });
    }
  };
  const downloadFile = async (fileName) => {
    setFileObj(null);
    setFileLoading(true);
    if (isEditMode && !fileName) {
      return;
    }
    try {
      const url = `${apiConfig.BASE_API}/pdfs`;
      const params = {
        name: fileName || "HelpFile.html.pdf",
      };
      const response = await axios.get(url, {
        responseType: "arraybuffer",
        params,
      });
      if (response.status === 200) {
        // Convert the binary data to a Blob
        const contentType = response.headers["content-type"];
        const pdfBlob = new Blob([response.data], { type: contentType });
        // Generate a URL for the Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setFileObj(pdfUrl);
      }
      setFileLoading(false);
    } catch (error) {
      setFileLoading(false);
      console.error("Error loading PDF:", error);
    }
  };
  useEffect(() => {
    if (!formik.values?.pdfName) {
      return;
    }
    downloadFile(formik.values?.pdfName);
    if (!isEditMode && formik.values?.pdfName) {
      getFileInformation(formik.values?.pdfName);
    }
    formik.resetForm();
    formik.setValues({
      ...formik.values,
      pdfName: formik.values?.pdfName,
      invoiceDate: isEditMode ? formik.values?.invoiceDate : null,
      invoiceDueDate: isEditMode ? formik.values?.invoiceDueDate : null,
    });
    if (!isEditMode) {
      setConfidence({
        bookingLines: [
          {
            taxRate: 0,
            currency: 0,
            amount: 0,
          },
        ],
      });
      document.getElementById("invoiceDate").value = "";
      document.getElementById("invoiceDueDate").value = "";
    }
  }, [formik.values?.pdfName]);

  const getInformation = async () => {
    try {
      const res = await axios.get(`${apiConfig.BASE_API}/booking/informations`);
      if (res.status == 200) {
        setCompaniesList(
          res.data.companies.map((e) => {
            return { value: e.id, name: e.name };
          }) ?? []
        );
        const vendorNameListItem = res.data?.vendorNames?.map((e) => {
          return { value: e.name, name: e.name, label: e.name };
        });
        setVendorNamesList(
          res.data?.vendorNames
            ?.map((e) => {
              return { value: e.name, name: e.name, label: e.name };
            })
            .filter((e) => e.value) ?? []
        );
        setPaymentMethodList(
          res.data.paymentMethods.map((e) => {
            return { value: e.id, name: e.name };
          }) ?? []
        );
        setTaxRateList(
          res.data.taxRates.map((e) => {
            return {
              value: e.name,
              name: e.name + " " + e.value,
              rate: e.value,
            };
          }) ?? []
        );
        setAccountNumberList(
          res.data.accountNumberList.map((e) => {
            return {
              value: e.number,
              name: e.number + " " + e.description,
              label: e.number + " " + e.description,
            };
          }) ?? []
        );
        setCurrencyList(
          res.data.currencies.map((e) => {
            return { value: e.id, name: e.name };
          }) ?? []
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (!formik.values?.companyId) {
      formik.setValues({
        ...formik.values,
        companyId: companiesList[0]?.id,
        taxRate: taxRateList.find((e) => e.name.toLowerCase() == "hoog")?.id,
      });
    }
  }, [formik.values?.companyId]);

  useEffect(() => {
    getInformation();
  }, []);
  const addBookingLines = () => {
    formik.setTouched({ ...formik.touched, bookingLines: {} }, false);
    formik.setValues({
      ...formik.values,
      bookingLines: [
        {
          accountNumber: null,
          taxRate: "Hoog",
          amount: "",
          amountInEUR: 0,
          currency: "EUR",
          taxAmount: 0,
          taxAmountInEUR: 0,
        },
        ...(formik.values?.bookingLines || []),
      ],
    });
    setConfidence({
      ...confidence,
      bookingLines: [
        {
          taxRate: 1,
          currency: 1,
          amount: 1,
        },
        ...confidence.bookingLines,
      ],
    });
  };
  const deleteBookingLinesClicked = (e, index) => {
    setBookLinesToDelete(index);
    setAnchorEl(e.currentTarget);
    setShowDeletePopover(true);
  };
  const deleteBookingLinesAtIndex = () => {
    formik.setValues({
      ...formik.values,
      bookingLines: [
        ...(formik.values?.bookingLines?.filter(
          (_, i) => bookLinesToDelete != i
        ) || []),
      ],
    });
    setAnchorEl(null);
    setShowDeletePopover(false);
    formik.setTouched({ ...formik.touched, bookingLines: {} }, false);
  };

  const getExchangeRate = (date) => {
    if (!date) return;
    const url = `${apiConfig.BASE_API}/booking/exchange-rates`;
    const params = {
      base: "EUR",
      symbols: "USD,EUR,GBP,BTC",
      date: date,
    };

    axios
      .get(url, { params })
      .then((response) => {
        setRates(response.data.rates);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };
  useEffect(() => {
    getExchangeRate(formik.values?.invoiceDate);
  }, [formik.values?.invoiceDate]);
  const calculateAmountInEUR = (currencyName, amount) => {
    const number_decimal = extractNumber(amount);
    if (!amount || isNaN(number_decimal)) {
      return 0;
    }
    if (!rates) {
      getExchangeRate(formik.values?.invoiceDate);
      return 0;
    }
    const rate = rates[currencyName];
    const amountInEUR = number_decimal / rate;
    return (
      parseFloat(amountInEUR)
        .toFixed(2)
        .replace(/\.?0*$/, "") || 0
    );
  };
  const getPrecision = (currencyName) => {
    return currencyName == "BTC" ? 8 : 2;
  };
  const calculateTaxAmountInEUR = (amount, taxRateId, currencyName) => {
    const number_decimal = extractNumber(amount);
    if (!amount || isNaN(number_decimal)) return 0;
    if (!rates) {
      getExchangeRate(formik.values?.invoiceDate);
      return 0;
    }
    const taxRate = taxRateList.find((tax) => tax.value == taxRateId);
    const rate = rates[currencyName];
    if (!rate) return 0;
    const amountInEUR = number_decimal / rate;
    const taxAmount = amountInEUR * (taxRate?.rate / 100);
    const roundeAmount = Math.round(taxAmount * 100) / 100;
    return roundeAmount.toFixed(2).replace(/\.?0*$/, "");
  };
  const calculateTaxAmount = (amount, taxRateId, currencyName) => {
    const number_decimal = extractNumber(amount);
    if (!amount || isNaN(number_decimal)) return 0;
    const taxRate = taxRateList.find((tax) => tax.value == taxRateId);
    const taxAmount = number_decimal * (taxRate?.rate / 100);
    const roundeAmount = Math.round(taxAmount * 100) / 100;
    return roundeAmount
      .toFixed(getPrecision(currencyName))
      .replace(/\.?0*$/, "");
  };
  const updateAmountInEUR = () => {
    if (!rates) {
      getExchangeRate(formik.values?.invoiceDate);
      return 0;
    }
    const bookingLines = formik.values?.bookingLines?.map((item) => {
      return {
        ...item,
        amountInEUR: calculateAmountInEUR(item.currency, item.amount),
        taxAmountInEUR: item.taxRate
          ? calculateAmountInEUR(item.currency, item.taxAmount)
          : item.taxAmountInEUR,
      };
    });
    formik.setFieldValue("bookingLines", [...bookingLines]);
  };
  useEffect(() => {
    updateAmountInEUR();
  }, [rates]);
  const isNumberValid = (currency, amount) => {
    if (!amount) {
      return false;
    }

    const btcPattern =
      /^(-?$|^-?\d{1,13}([\.,]\d{0,8})?)$|^(.?$|^.?\d{1,8}?)$|^(-|\.\d{8}|-\.\d{1,8}|-\.)$/;
    const otherCurrencyPattern =
      /^(-?$|^-?\d{1,13}([\.,]\d{0,2})?)$|^(.?$|^.?\d{1,2}?)$|^(-|\.\d{2}|-\.\d{1,2}|-\.)$/;

    const pattern = currency === "BTC" ? btcPattern : otherCurrencyPattern;
    const isValid = pattern.test(amount);

    if (!isValid) {
      return false;
    }
    if (amount.startsWith("-")) {
      return amount.length > 3 || !/-?0[0-9]/.test(amount);
    } else {
      return amount.length > 2 || !/-?0[0-9]/.test(amount);
    }
  };
  const setAmountNumber = (event, index) => {
    const amount = event.target.value;
    const isValidAmount = isNumberValid(
      formik.values?.bookingLines[index].currency,
      amount
    );
    setConfidence({
      ...confidence,
      bookingLines: confidence.bookingLines.map((line, i) => {
        if (i === index) {
          return {
            ...line,
            amount: 1,
          };
        }
        return line;
      }),
    });
    if (isValidAmount || amount === "") {
      const updatedBookingLines = formik.values?.bookingLines?.map(
        (line, i) => {
          if (i === index) {
            return {
              ...line,
              amount: amount,
              amountInEUR: calculateAmountInEUR(line.currency, amount),
              taxAmount: line.taxRate
                ? calculateTaxAmount(amount, line.taxRate, line.currency)
                : line.taxAmount,
              taxAmountInEUR: line.taxRate
                ? calculateTaxAmountInEUR(amount, line.taxRate, line.currency)
                : line.taxAmountInEUR,
            };
          }
          return line;
        }
      );

      formik.setValues({
        ...formik.values,
        bookingLines: updatedBookingLines,
      });
    }
  };
  const setCurrency = (event, index) => {
    const currency = event.target.value;
    const updatedBookingLines = formik.values?.bookingLines.map((line, i) => {
      if (i === index) {
        return {
          ...line,
          currency,

          amountInEUR: calculateAmountInEUR(currency, line.amount),
          taxAmount: line.taxRate
            ? calculateTaxAmount(line.amount, line.taxRate, currency)
            : line.taxAmount,
          taxAmountInEUR: line.taxRate
            ? calculateTaxAmountInEUR(line.amount, line.taxRate, currency)
            : line.taxAmountInEUR,
        };
      }
      return line;
    });
    setConfidence({
      ...confidence,
      bookingLines: confidence.bookingLines.map((line, i) => {
        if (i === index) {
          return {
            ...line,
            currency: 1,
          };
        }
        return line;
      }),
    });
    formik.setValues({
      ...formik.values,
      bookingLines: updatedBookingLines,
    });
  };
  const setTaxRate = (event, index) => {
    const taxRate = event.target.value;
    if (!taxRate) return;
    const updatedBookingLines = formik.values?.bookingLines.map((line, i) => {
      if (i === index) {
        return {
          ...line,
          taxRate: taxRate,
          taxAmount: taxRate
            ? calculateTaxAmount(line.amount, taxRate, line.currency)
            : line.taxAmount,
          taxAmountInEUR: taxRate
            ? calculateTaxAmountInEUR(line.amount, taxRate, line.currency)
            : taxRate.taxAmountInEUR,
        };
      }
      return line;
    });
    setConfidence({
      ...confidence,
      bookingLines: confidence.bookingLines.map((line, i) => {
        if (i === index) {
          return {
            ...line,
            taxRate: 1,
          };
        }
        return line;
      }),
    });
    formik.setValues({
      ...formik.values,
      bookingLines: updatedBookingLines,
    });
  };
  const convertCurrencyToEUR = (currency, amount) => {
    const number_decimal = extractNumber(amount);
    if (!rates || isNaN(number_decimal)) {
      return 0;
    }

    const rate = rates[currency];
    return parseFloat(number_decimal / rate)
      .toFixed(2)
      .replace(/\.?0*$/, "");
  };
  const getConfidenceClass = (value) => {
    if (!value) return "";
    const confidence = parseFloat(value);
    if (confidence < 0.5) {
      return " error ";
    }
    if (confidence < 0.8) {
      return " warn ";
    }
    if (confidence < 0.98) {
      return " check ";
    }
    return "";
  };
  const setTaxRateAmount = (event, index) => {
    const taxAmount = event.target.value;
    const isValidAmount = isNumberValid(
      formik.values?.bookingLines[index].currency,
      taxAmount
    );
    if (isValidAmount || taxAmount === "") {
      const updatedBookingLines = formik.values?.bookingLines.map((line, i) => {
        if (i === index) {
          return {
            ...line,
            taxAmount,
            taxAmountInEUR: convertCurrencyToEUR(line.currency, taxAmount),
          };
        }
        return line;
      });

      formik.setValues({
        ...formik.values,
        bookingLines: updatedBookingLines,
      });
    }
  };
  const setAccountNumber = (value, index) => {
    const updatedBookingLines = formik.values?.bookingLines.map((line, i) => {
      if (i === index) {
        return {
          ...line,
          accountNumber: value?.value ?? null,
        };
      }
      return line;
    });

    formik.setValues({
      ...formik.values,
      bookingLines: updatedBookingLines,
    });
  };

  const sumBookingLinesItem = () => {
    const sum = formik.values?.bookingLines
      ?.map(
        (item) =>
          extractNumber(item.amountInEUR) + extractNumber(item.taxAmountInEUR)
      )
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    return "€" + toExponentialNumber(sum);
  };
  const getSubTotalInEUR = (bookingLine) => {
    const amountInEUR = extractNumber(bookingLine.amountInEUR);
    const taxAmountInEUR = extractNumber(bookingLine.taxAmountInEUR);
    return "€" + toExponentialNumber(amountInEUR + taxAmountInEUR);
  };
  const getSubTotal = (bookingLine) => {
    const currencySymbol = getSymbol(bookingLine.currency);
    const amountInEUR = extractNumber(bookingLine.amount);
    const taxAmountInEUR = extractNumber(bookingLine.taxAmount);
    return currencySymbol + toExponentialNumber(amountInEUR + taxAmountInEUR);
  };
  return (
    <>
      <CssBaseline />

      <main>
        <div className={classes.root}>
          <Grid container spacing={0} style={{ height: "100%" }}>
            <Grid item xs={12} sm={5}>
              <Paper className={classes.xmlContainer}>
                {/* <Grid container>
                    <Grid item xs={12} sm={1}>
                      <IconButton
                        onClick={() => history.goBack()}
                        color="primary"
                        aria-label="add an alarm"
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <Typography
                        className={classes.sectionHeader}
                        align="center"
                        // gutterBottom
                      >
                        {data.default[language].createPageTitle}
                      </Typography>
                    </Grid>
                  </Grid> */}
                <div className={classes.paper}>
                  <form
                    onSubmit={formik.handleSubmit}
                    className={classes.form}
                    noValidate
                  >
                    <TextField
                      className={classes.selectText}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values?.pdfName}
                      name="pdfName"
                      variant="outlined"
                      margin="normal"
                      label={data.default[language].fields.image}
                      size="small"
                      SelectProps={{
                        classes: { icon: classes.icon },
                      }}
                      select
                    >
                      {filesList
                        ? filesList.map((f) => {
                          return (
                            <MenuItem key={f.value} value={f}>
                              {f}
                            </MenuItem>
                          );
                        })
                        : ""}
                    </TextField>
                    {formik.touched.pdfName && formik.errors.pdfName ? (
                      <div className={classes.formError}>
                        {formik.errors.pdfName}
                      </div>
                    ) : null}

                    <TextField
                      className={classes.selectText}
                      name="companyId"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values?.companyId}
                      variant="outlined"
                      margin="normal"
                      label={data.default[language].fields.company}
                      size="small"
                      SelectProps={{
                        classes: { icon: classes.icon },
                      }}
                      select
                    >
                      {companiesList
                        ? companiesList.map((f) => {
                          return (
                            <MenuItem key={f.value} value={f.value}>
                              {f.name}
                            </MenuItem>
                          );
                        })
                        : ""}
                    </TextField>
                    {formik.touched.companyId && formik.errors.companyId ? (
                      <div className={classes.formError}>
                        {formik.errors.companyId}
                      </div>
                    ) : null}
                    <Grid container spacing={2}>
                      <Grid item xs="6">
                        <TextField
                          className={
                            getConfidenceClass(confidence.invoiceIBAN) +
                            classes.selectText
                          }
                          onChange={(e, value) => {
                            formik.setFieldValue("invoiceIBAN", e.target.value);
                            setConfidence({ ...confidence, invoiceIBAN: 1 });
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values?.invoiceIBAN}
                          name="invoiceIBAN"
                          variant="outlined"
                          margin="normal"
                          size="small"
                          InputProps={{
                            endAdornment: confidence.invoiceIBAN != 1 &&
                              extractLoading && <TextFieldLoading />,
                          }}
                          label={data.default[language].fields.invoiceIBAN}
                          SelectProps={{
                            classes: { icon: classes.icon },
                          }}
                        ></TextField>
                        {formik.touched.invoiceIBAN &&
                          formik.errors.invoiceIBAN ? (
                          <div className={classes.formError}>
                            {formik.errors.invoiceIBAN}
                          </div>
                        ) : null}
                      </Grid>
                      <Grid item xs="6">
                        <Autocomplete
                          disablePortal
                          isOptionEqualToValue={(option, value) => {
                            return option.name === value;
                          }}
                          value={
                            vendorNamesList?.find(
                              (e) => e.value == formik.values?.vendorName
                            )?.label || null
                          }
                          sx={{
                            "& .MuiSvgIcon-root": {
                              color: "#64ffda",
                            },
                            "& .MuiInputBase-input": {
                              textAlign: "center",
                            },
                          }}
                          options={vendorNamesList}
                          onChange={(e, value) => {
                            formik.setFieldValue("vendorName", value?.name);
                            setConfidence({ ...confidence, vendorName: 1 });
                          }}
                          onBlur={(e, value) => {
                            if (
                              e.target?.value &&
                              e.target?.value !== formik.values?.vendorName
                            ) {
                              formik.setFieldValue(
                                "vendorName",
                                e.target?.value
                              );
                              addNewVendorName(e.target?.value);

                              setConfidence({ ...confidence, vendorName: 1 });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className={classes.selectText}
                              // value={_bookingLines.accountNumber}
                              variant="outlined"
                              margin="normal"
                              size="small"
                              label={data.default[language].fields.vendorName}
                            />
                          )}
                        />
                        {formik.touched?.vendorName &&
                          formik.errors?.vendorName ? (
                          <div className={classes.formError}>
                            {formik.errors?.vendorName}
                          </div>
                        ) : null}
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs="4">
                        <TextField
                          className={
                            getConfidenceClass(confidence.invoiceNumber) +
                            classes.selectText
                          }
                          onChange={(e, value) => {
                            formik.setFieldValue(
                              "invoiceNumber",
                              e.target.value
                            );
                            setConfidence({ ...confidence, invoiceNumber: 1 });
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values?.invoiceNumber}
                          name="invoiceNumber"
                          variant="outlined"
                          margin="normal"
                          size="small"
                          InputProps={{
                            endAdornment: confidence.invoiceNumber != 1 &&
                              extractLoading && <TextFieldLoading />,
                          }}
                          label={data.default[language].fields.invoiceNumber}
                          SelectProps={{
                            classes: { icon: classes.icon },
                          }}
                        ></TextField>
                        {formik.touched.invoiceNumber &&
                          formik.errors.invoiceNumber ? (
                          <div className={classes.formError}>
                            {formik.errors.invoiceNumber}
                          </div>
                        ) : null}
                      </Grid>
                      <Grid item xs="4">
                        <TextField
                          variant="outlined"
                          size="small"
                          margin="normal"
                          fullWidth
                          InputProps={{
                            endAdornment: confidence.invoiceDate != 1 &&
                              extractLoading && <TextFieldLoading />,
                          }}
                          name="invoiceDate"
                          className={
                            classes.selectText +
                            getConfidenceClass(confidence.invoiceDate)
                          }
                          label={data.default[language].fields.invoiceDate}
                          type="date"
                          onChange={(e, value) => {
                            formik.setFieldValue("invoiceDate", e.target.value);
                            if (!isEditMode) {
                              getFiles(e.target.value);
                            }
                            setConfidence({ ...confidence, invoiceDate: 1 });
                          }}
                          // onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={
                            formik.values?.invoiceDate
                              ? new Date(formik.values?.invoiceDate)
                                .toISOString()
                                .split("T")[0]
                              : null
                          }
                          id="invoiceDate"
                          inputProps={{
                            max: new Date().toISOString().split("T")[0],
                          }}
                          InputLabelProps={{
                            shrink: true,
                            classes: {
                              root: classes.cssLabel,
                            },
                          }}
                          SelectProps={{
                            classes: { icon: classes.dateIcon },
                          }}
                        />
                        {formik.touched.invoiceDate &&
                          formik.errors.invoiceDate ? (
                          <div className={classes.formError}>
                            {formik.errors.invoiceDate}
                          </div>
                        ) : null}
                      </Grid>
                      <Grid item xs="4">
                        <TextField
                          variant="outlined"
                          size="small"
                          margin="normal"
                          fullWidth
                          InputProps={{
                            endAdornment: confidence.invoiceDueDate != 1 &&
                              extractLoading && <TextFieldLoading />,
                          }}
                          name="invoiceDueDate"
                          className={
                            classes.selectText +
                            getConfidenceClass(confidence.invoiceDueDate)
                          }
                          type="date"
                          label={data.default[language].fields.invoiceDueDate}
                          onChange={(e, value) => {
                            formik.setFieldValue(
                              "invoiceDueDate",
                              e.target.value
                            );
                            setConfidence({ ...confidence, invoiceDueDate: 1 });
                          }}
                          // onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={
                            formik.values?.invoiceDueDate
                              ? new Date(formik.values?.invoiceDueDate)
                                .toISOString()
                                .split("T")[0]
                              : null
                          }
                          id="invoiceDueDate"
                          InputLabelProps={{
                            shrink: true,
                            classes: {
                              root: classes.cssLabel,
                            },
                          }}
                          SelectProps={{
                            classes: { icon: classes.dateIcon },
                          }}
                        />
                        {formik.touched.invoiceDueDate &&
                          formik.errors.invoiceDueDate ? (
                          <div className={classes.formError}>
                            {formik.errors.invoiceDueDate}
                          </div>
                        ) : null}
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs="4">
                        <TextField
                          className={classes.selectText}
                          name="paidById"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values?.paidById}
                          variant="outlined"
                          margin="normal"
                          label={data.default[language].fields.paidBy}
                          size="small"
                          SelectProps={{
                            classes: { icon: classes.icon },
                          }}
                          select
                        >
                          {paymentMethodList?.map((f) => {
                            return (
                              <MenuItem key={f.value} value={f.value}>
                                {f.name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          className={classes.selectText}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values?.description}
                          name="description"
                          variant="outlined"
                          margin="normal"
                          size="small"
                          label={data.default[language].fields.description}
                          SelectProps={{
                            classes: { icon: classes.icon },
                          }}
                          rows={1}
                        ></TextField>
                        {formik.touched.description &&
                          formik.errors.description && (
                            <div className={classes.formError}>
                              {formik.errors.description}
                            </div>
                          )}
                      </Grid>
                    </Grid>

                    {/* Booking Line Section Start */}
                    <div className={classes.bookingItemsContainer}>
                      <div className={classes.bookingItemsheaderContainer}>
                        <div>
                          <div className={classes.bookingItemsheaderHolder}>
                            <Grid
                              container
                              spacing={2}
                              alignContent="center"
                              alignItems="center"
                            >
                              <Grid item>
                                <Typography
                                  className={classes.sectionSubHeader}
                                  align="center"
                                  gutterBottom
                                >
                                  {data.default[language].bookingLinesItems}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Button
                                  disabled={formik.isSubmitting}
                                  onClick={() => addBookingLines()}
                                  fullWidth
                                  variant="contained"
                                  color="primary"
                                  classes={{
                                    root: classes.addBookingLinesButton,
                                  }}
                                >
                                  {data.default[language].addMore}
                                </Button>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.bookingTotalItem}>
                            <Typography variant="button" align="center">
                              {data.default[language].total} :{" "}
                              {sumBookingLinesItem()}
                            </Typography>
                          </div>
                        </div>
                      </div>

                      {formik.values?.bookingLines?.map(
                        (_bookingLines, index) => {
                          return (
                            <Grid key={index} container>
                              <input
                                type="hidden"
                                name={`bookingLines[${index}][id]`}
                                value={_bookingLines.id}
                              />
                              <Grid item xs={11}>
                                <div>
                                  <Autocomplete
                                    disablePortal
                                    isOptionEqualToValue={(option, value) => {
                                      return option.name === value;
                                    }}
                                    value={
                                      accountNumberList.find(
                                        (e) =>
                                          e.value == _bookingLines.accountNumber
                                      )?.label || null
                                    }
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        color: "#64ffda",
                                      },
                                      "& .MuiInputBase-input": {
                                        textAlign: "center",
                                      },
                                    }}
                                    options={accountNumberList}
                                    onChange={(e, value) =>
                                      setAccountNumber(value, index)
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        className={classes.selectText}
                                        // value={_bookingLines.accountNumber}
                                        variant="outlined"
                                        margin="normal"
                                        size="small"
                                        label={
                                          data.default[language].fields
                                            .accountNumber
                                        }
                                      />
                                    )}
                                  />
                                  {formik.errors?.bookingLines &&
                                    formik.touched.bookingLines &&
                                    formik.touched?.bookingLines[index]
                                      ?.accountNumber &&
                                    formik.errors?.bookingLines[index]
                                      ?.accountNumber && (
                                      <div className={classes.formError}>
                                        {
                                          formik.errors?.bookingLines[index]
                                            ?.accountNumber
                                        }
                                      </div>
                                    )}
                                  <Grid spacing={2} container>
                                    <Grid item xs={4}>
                                      <TextField
                                        variant="outlined"
                                        margin="normal"
                                        size="small"
                                        fullWidth
                                        select
                                        className={
                                          classes.selectText +
                                          getConfidenceClass(
                                            confidence?.bookingLines[index]
                                              ?.currency
                                          )
                                        }
                                        name={`bookingLines[${index}][currency]`}
                                        {...formik.getFieldProps(
                                          `bookingLines[${index}][currency]`
                                        )}
                                        onChange={(e) => setCurrency(e, index)}
                                        value={_bookingLines.currency}
                                        label={
                                          data.default[language].fields.currency
                                        }
                                        type="text"
                                        id="currency"
                                        InputProps={{
                                          classes: {
                                            input: classes.inputColor,
                                          },
                                          endAdornment: confidence
                                            ?.bookingLines[index]?.currency !=
                                            1 &&
                                            extractLoading && (
                                              <TextFieldLoading />
                                            ),
                                        }}
                                        SelectProps={{
                                          classes: { icon: classes.icon },
                                        }}
                                        InputLabelProps={{
                                          classes: {
                                            root: classes.cssLabel,
                                          },
                                        }}
                                      >
                                        {currencyList?.map((f) => {
                                          return (
                                            <MenuItem
                                              key={f.name}
                                              value={f.name}
                                            >
                                              {f.name}
                                            </MenuItem>
                                          );
                                        })}
                                      </TextField>
                                      {formik.errors.bookingLines &&
                                        formik.touched.bookingLines &&
                                        formik.touched.bookingLines[index]
                                          ?.currency &&
                                        formik.errors.bookingLines[index]
                                          ?.currency && (
                                          <div className={classes.formError}>
                                            {
                                              formik.errors.bookingLines[index]
                                                ?.currency
                                            }
                                          </div>
                                        )}
                                    </Grid>
                                    <Grid item xs={4}>
                                      <TextField
                                        variant="outlined"
                                        margin="normal"
                                        size="small"
                                        fullWidth
                                        className={
                                          classes.selectText +
                                          getConfidenceClass(
                                            confidence?.bookingLines[index]
                                              ?.amount
                                          )
                                        }
                                        name={`bookingLines[${index}][amount]`}
                                        {...formik.getFieldProps(
                                          `bookingLines[${index}][amount]`
                                        )}
                                        onChange={(e) =>
                                          setAmountNumber(e, index)
                                        }
                                        value={_bookingLines.amount}
                                        step="0.01"
                                        label={
                                          data.default[language].fields.amount
                                        }
                                        type="text"
                                        id="amount"
                                        InputProps={{
                                          classes: {
                                            input: classes.inputColor,
                                          },
                                          endAdornment: confidence
                                            ?.bookingLines[index]?.amount !=
                                            1 &&
                                            extractLoading && (
                                              <TextFieldLoading />
                                            ),
                                        }}
                                        InputLabelProps={{
                                          classes: {
                                            root: classes.cssLabel,
                                          },
                                        }}
                                      />
                                      {formik.errors.bookingLines &&
                                        formik.touched.bookingLines &&
                                        formik.touched.bookingLines[index]
                                          ?.amount &&
                                        formik.errors.bookingLines[index]
                                          ?.amount && (
                                          <div className={classes.formError}>
                                            {
                                              formik.errors.bookingLines[index]
                                                ?.amount
                                            }
                                          </div>
                                        )}
                                    </Grid>
                                    <Grid item xs={4}>
                                      <TextField
                                        variant="outlined"
                                        margin="normal"
                                        size="small"
                                        fullWidth
                                        name={`bookingLines[${index}][amountInEUR]`}
                                        {...formik.getFieldProps(
                                          `bookingLines[${index}][amountInEUR]`
                                        )}
                                        onChange={null}
                                        value={_bookingLines.amountInEUR}
                                        step="0.01"
                                        label={
                                          data.default[language].fields
                                            .amountInEUR
                                        }
                                        type="text"
                                        id="amountInEUR"
                                        InputProps={{
                                          classes: {
                                            input: classes.inputColor,
                                          },
                                        }}
                                        InputLabelProps={{
                                          shrink: true,
                                          classes: {
                                            root: classes.cssLabel,
                                          },
                                        }}
                                      />
                                      {formik.errors.bookingLines &&
                                        formik.touched.bookingLines &&
                                        formik.touched.bookingLines[index]
                                          ?.amountInEUR &&
                                        formik.errors.bookingLines[index]
                                          ?.amountInEUR && (
                                          <div className={classes.formError}>
                                            {
                                              formik.errors.bookingLines[index]
                                                ?.amountInEUR
                                            }
                                          </div>
                                        )}
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                      <TextField
                                        className={
                                          classes.selectText +
                                          getConfidenceClass(
                                            confidence?.bookingLines[index]
                                              ?.taxRate
                                          )
                                        }
                                        name={`bookingLines[${index}][taxRate]`}
                                        {...formik.getFieldProps(
                                          `bookingLines[${index}][taxRate]`
                                        )}
                                        onChange={(e) => setTaxRate(e, index)}
                                        variant="outlined"
                                        margin="normal"
                                        size="small"
                                        InputProps={{
                                          endAdornment: confidence
                                            ?.bookingLines[index]?.taxRate !=
                                            1 &&
                                            extractLoading && (
                                              <TextFieldLoading />
                                            ),
                                        }}
                                        label={
                                          data.default[language].fields.taxRate
                                        }
                                        SelectProps={{
                                          classes: { icon: classes.icon },
                                        }}
                                        select
                                      >
                                        {taxRateList?.map((f) => {
                                          return (
                                            <MenuItem
                                              key={f.value}
                                              value={f.value}
                                            >
                                              {f.name}
                                            </MenuItem>
                                          );
                                        })}
                                      </TextField>
                                      {formik.errors.bookingLines &&
                                        formik.touched.bookingLines &&
                                        formik.touched.bookingLines[index]
                                          ?.taxRate &&
                                        formik.errors.bookingLines[index]
                                          ?.taxRate && (
                                          <div className={classes.formError}>
                                            {
                                              formik.errors.bookingLines[index]
                                                ?.taxRate
                                            }
                                          </div>
                                        )}
                                      <Typography
                                        className={classes.subTotalItem}
                                        variant="button"
                                        align="center"
                                      >
                                        {data.default[language].subTotal} :
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <TextField
                                        variant="outlined"
                                        margin="normal"
                                        size="small"
                                        fullWidth
                                        name={`bookingLines[${index}][taxAmount]`}
                                        {...formik.getFieldProps(
                                          `bookingLines[${index}][taxAmount]`
                                        )}
                                        value={_bookingLines.taxAmount}
                                        onChange={(e) =>
                                          setTaxRateAmount(e, index)
                                        }
                                        step="0.01"
                                        label={
                                          data.default[language].fields
                                            .taxAmount
                                        }
                                        type="text"
                                        id="taxAmount"
                                        InputProps={{
                                          classes: {
                                            input: classes.inputColor,
                                          },
                                        }}
                                        InputLabelProps={{
                                          shrink: true,
                                          classes: {
                                            root: classes.cssLabel,
                                          },
                                        }}
                                      />
                                      {formik.errors.bookingLines &&
                                        formik.touched.bookingLines &&
                                        formik.touched.bookingLines[index]
                                          ?.taxAmount &&
                                        formik.errors.bookingLines[index]
                                          ?.taxAmount && (
                                          <div className={classes.formError}>
                                            {
                                              formik.errors.bookingLines[index]
                                                ?.taxAmount
                                            }
                                          </div>
                                        )}
                                      <Grid
                                        className={classes.subTotalItem}
                                        item
                                      >
                                        {getSubTotal(_bookingLines)}
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <TextField
                                        variant="outlined"
                                        margin="normal"
                                        size="small"
                                        fullWidth
                                        name={`bookingLines[${index}][taxAmountInEUR]`}
                                        {...formik.getFieldProps(
                                          `bookingLines[${index}][taxAmountInEUR]`
                                        )}
                                        value={_bookingLines.taxAmountInEUR}
                                        onChange={null}
                                        label={
                                          data.default[language].fields
                                            .taxAmountInEUR
                                        }
                                        type="text"
                                        id="taxAmountInEUR"
                                        InputProps={{
                                          classes: {
                                            input: classes.inputColor,
                                          },
                                        }}
                                        InputLabelProps={{
                                          shrink: true,
                                          classes: {
                                            root: classes.cssLabel,
                                          },
                                        }}
                                      />
                                      {formik.errors.bookingLines &&
                                        formik.touched.bookingLines &&
                                        formik.touched.bookingLines[index]
                                          ?.taxAmountInEUR &&
                                        formik.errors.bookingLines[index]
                                          ?.taxAmountInEUR && (
                                          <div className={classes.formError}>
                                            {
                                              formik.errors.bookingLines[index]
                                                ?.taxAmountInEUR
                                            }
                                          </div>
                                        )}
                                      <Grid
                                        className={classes.subTotalItem}
                                        item
                                      >
                                        {getSubTotalInEUR(_bookingLines)}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className={classes.bookingSubTotalHolder}>
                                  {/* <div className={classes.subTotal}>
                                    <Grid container>
                                      <Grid
                                        
                                        item
                                        xs={3}
                                      >
                                        <Typography
                                          className={classes.subTotalItem}
                                          variant="button"
                                          align="center"
                                        >
                                          {data.default[language].subTotal} :
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <Grid
                                          container
                                          justify="space-evenly"
                                          alignItems="center"
                                        >
                                          <Grid
                                            className={classes.subTotalItem}
                                            item
                                          >
                                            {getSubTotal(_bookingLines)}
                                          </Grid>
                                          <Grid
                                            className={classes.subTotalItem}
                                            item
                                          >
                                            {getSubTotalInEUR(_bookingLines)}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div> */}
                                  {formik.values?.bookingLines.length - 1 !==
                                    index && (
                                      <hr className={classes.itemDivider} />
                                    )}
                                </div>
                              </Grid>
                              <Grid
                                container
                                item
                                xs={1}
                                alignItems="center"
                                justifyContent="center"
                              >
                                <IconButton
                                  disabled={
                                    formik.values?.bookingLines.length == 1
                                  }
                                  onClick={(e) =>
                                    deleteBookingLinesClicked(e, index)
                                  }
                                  className={classes.centerItems}
                                  aria-label="delete bookinglines"
                                >
                                  <DeleteForeverIcon
                                    color={
                                      formik.values?.bookingLines.length == 1
                                        ? "action"
                                        : "error"
                                    }
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>
                          );
                        }
                      )}
                    </div>
                    {/* Booking Line Section End */}
                    <Button
                      disabled={isLoading}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      classes={{
                        root: classes.button,
                      }}
                    >
                      {isEditMode
                        ? data.default[language].update
                        : data.default[language].create}
                    </Button>
                  </form>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Paper className={classes.previewContainer}>
                {/* <Typography
                    className={classes.sectionHeader}
                    align="center"
                    gutterBottom
                  >
                    Test PDF Preview
                  </Typography> */}
                {fileLoading ? (
                  <CircularProgress />
                ) : (
                  <>
                    {formik.values?.pdfName?.endsWith(".pdf") ||
                      !formik.values?.pdfName ? (
                      <Iframe
                        key={formik.values?.pdfName || "HelpFile.html.pdf"}
                        url={
                          fileObj +
                          "#navpanes=0&statusbar=0&toolbar=0&view=FitH"
                        }
                        width="100%"
                        id="myId"
                        className="iframe"
                        display="initial"
                        position="relative"
                      />
                    ) : (
                      <div className="imagePreviewContainer">
                        <img className="imagePreview" src={fileObj} />
                      </div>
                    )}
                  </>
                )}
              </Paper>
            </Grid>
          </Grid>
          <ErrorDialog
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            lang={data.default[language]}
          />
          <Popover
            open={showDeletePopover}
            anchorEl={anchorEl}
            onClose={() => setShowDeletePopover(false)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <div className={classes.deletePopverContainer}>
              <span className={classes.deletePopverHeader}>
                {data.default[language].sureConfirmMsg}
              </span>
              <hr className={classes.deletePopoverDivider} />
              <Grid container spacing={3}>
                <Grid item>
                  <Button
                    onClick={() => setShowDeletePopover(false)}
                    variant="outlined"
                  >
                    {data.default[language].cancel}{" "}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => deleteBookingLinesAtIndex()}
                  >
                    {data.default[language].delete}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Popover>
        </div>
      </main>
    </>
  );
};

export default Form;
